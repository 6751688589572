



























































import { Component, Vue } from 'vue-property-decorator'
import LsFooter from '@/components/layout/footer.vue'
import { apiLogin } from '@/api/app'
import { Action } from 'vuex-class'
import cache from '@/utils/cache'
@Component({
    components: {
        LsFooter
    }
})
export default class Login extends Vue {
    $refs!: { form: any }
    @Action('getPermission') getPermission!: () => void
    rememberAccount = false
    accountObj = {
        account: '',
        password: ''
    }
    rules: any = {
        account: [
            {
                required: true,
                message: '请输入账号',
                trigger: ['blur', 'change']
            }
        ],
        password: [
            {
                required: true,
                message: '请输入密码',
                trigger: ['blur', 'change']
            }
        ]
    }
    loadingLogin = false
    get config() {
        return this.$store.getters.config
    }
    // S Methods
    // 点击登录
    handleLogin() {
        this.$refs.form.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            cache.set('remember_account', {
                remember: this.rememberAccount,
                account: this.accountObj.account
            })
            this.login()
        })
    }
    //登录
    login() {
        this.loadingLogin = true
        const { account, password } = this.accountObj
        apiLogin({
            account,
            password,
            terminal: 1
        })
            .then(data => {
                this.$store.commit('setUserInfo', data)
                return this.getPermission()
            })
            .then(() => {
                const {
                    query: { redirect }
                } = this.$route
                const path = typeof redirect === 'string' ? redirect : '/'
                this.$router.replace(path)
            })
            .catch(() => {
                this.loadingLogin = false
            })
    }
    created() {
        const value = cache.get('remember_account')
        if (value.remember) {
            this.rememberAccount = value.remember
            this.accountObj.account = value.account
        }
    }
}
